import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Friends4EverForm({
  breadcrumbs,
  onSubmit: onSubmitProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [congregationId, setCongregationId] = useState(null);
  const [date, setDate] = useState(null);
  const [convidadosNaoCrentes, setConvidadosNaoCrentes] = useState(0);
  const [aceitaramVoltaram, setAceitaramVoltaram] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    onSubmitProp({
      congregacaoId: congregationId,
      data: date,
      convidadosNaoCrentes,
      aceitaramVoltaram,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      {breadcrumbs}
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          <CongregationSelect
            required={true}
            onChange={(value) => setCongregationId(value?.id ?? null)}
          />
          <DatePicker
            label={t("Date")}
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} required />}
          />
          <TextField
            label={t("Guests (Non-Believers)")}
            type="number"
            value={convidadosNaoCrentes}
            onChange={(e) => setConvidadosNaoCrentes(Number(e.target.value))}
            required
          />
          <TextField
            label={t("Accepted/Returned")}
            type="number"
            value={aceitaramVoltaram}
            onChange={(e) => setAceitaramVoltaram(Number(e.target.value))}
            required
          />

          {errorProp && <Alert severity="error">{t(errorProp)}</Alert>}

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
}
