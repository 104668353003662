import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  Autocomplete,
  FormControl,
  Container,
} from "@mui/material";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { usePeopleQuery } from "data/queries/queryPeople";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import fetch from "data/fetch";
import moment from "moment"; // Using moment for date formatting

const OfferingInput = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      type="text"
      fullWidth
      value={value}
      onChange={(e) => {
        let inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()}
      sx={{ mb: 2 }}
      required
    />
  );
};

export default function TreasuryDonationPage() {
  const { t } = useTranslation();
  const [donations, setDonations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDonation, setNewDonation] = useState({
    pessoaId: "",
    pessoaName: "",
    congregacaoId: null,
    data: "",
    valor: 0,
    tipo: "DIZIMO",
  });
  const [formError, setFormError] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const { data: peopleData, isLoading: isPeopleLoading } = usePeopleQuery({
    filter: searchFilter,
  });

  const donationTypes = ["DIZIMO", "OFERTA", "MISSOES", "VOTO", "OUTROS"];

  const fetchDonations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("/v1/doacoes", { method: "GET" });
      setDonations(response.data || []);
    } catch (e) {
      setError(t("Failed to load donations."));
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    fetchDonations();
  }, [fetchDonations]);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      t("Are you sure you want to delete this donation?")
    );

    if (!isConfirmed) {
      return; // Exit if user cancels
    }

    try {
      await fetch(`/v1/doacoes/${id}`, { method: "DELETE" });
      fetchDonations();
    } catch (e) {
      setError(t("Failed to delete donation."));
    }
  };

  const validateForm = () => {
    if (
      !newDonation.pessoaId ||
      !newDonation.congregacaoId ||
      !newDonation.data ||
      !newDonation.valor ||
      !newDonation.tipo
    ) {
      return t("All fields are required.");
    }
    return null;
  };

  const handleAddDonation = async () => {
    const validationError = validateForm();
    if (validationError) {
      setFormError(validationError);
      return;
    }

    setFormError(null);
    setError(null);
    try {
      await fetch("/v1/doacoes", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pessoaId:
            newDonation.pessoaId === "anonymous" ? null : newDonation.pessoaId,
          congregacaoId: newDonation.congregacaoId,
          data: newDonation.data,
          valor: parseFloat(newDonation.valor) || 0,
          tipo: newDonation.tipo,
        }),
      });
      setNewDonation({
        pessoaId: "",
        pessoaName: "",
        congregacaoId: null,
        data: "",
        valor: 0,
        tipo: "DIZIMO",
      });
      setIsModalOpen(false);
      fetchDonations();
    } catch (e) {
      setError(t("Failed to add donation."));
    }
  };

  return (
    <Container>
      <DetailsPageHeader
        heading={t("Donations")}
        label={t("Donation List")}
        routeIndex="/treasury/donation"
      />

      {error && <Alert severity="error">{error}</Alert>}

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsModalOpen(true)}
      >
        {t("New Donation")}
      </Button>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Person Name")}</TableCell>
              <TableCell>{t("Congregation")}</TableCell>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Amount")}</TableCell>
              <TableCell>{t("Type")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>{t("Loading...")}</TableCell>
              </TableRow>
            ) : donations.length > 0 ? (
              donations.map((donation) => (
                <TableRow key={donation.id}>
                  <TableCell>
                    {donation.pessoa ? donation.pessoa.name : t("Anonymous")}
                  </TableCell>
                  <TableCell>
                    {donation.congregacao?.name || t("N/A")}
                  </TableCell>
                  <TableCell>
                    {donation.data
                      ? moment(donation.data).format("MMM/DD/YYYY")
                      : t("N/A")}
                  </TableCell>
                  <TableCell>{donation.valor?.toFixed(2) || "0.00"}</TableCell>
                  <TableCell>{t(donation.tipo)}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDelete(donation.id)}
                    >
                      {t("Delete")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>{t("No donations found.")}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("New Donation")}</DialogTitle>
        <DialogContent>
          {formError && (
            <Alert sx={{ mb: 2, mt: 2 }} severity="error">
              {formError}
            </Alert>
          )}
          <Autocomplete
            value={
              newDonation.pessoaId
                ? { id: newDonation.pessoaId, name: newDonation.pessoaName }
                : null
            }
            options={[
              { id: "anonymous", name: t("Anonymous Person") },
              ...(peopleData?.data || []),
            ]}
            getOptionLabel={(option) => option.name || ""}
            onInputChange={(_, newInputValue) => setSearchFilter(newInputValue)}
            onChange={(_, newValue) =>
              setNewDonation({
                ...newDonation,
                pessoaId: newValue?.id || "",
                pessoaName: newValue?.name || "",
              })
            }
            loading={isPeopleLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Person")}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
            )}
          />
          <CongregationSelect
            sx={{ mb: 2 }}
            required
            onChange={(value) =>
              setNewDonation({
                ...newDonation,
                congregacaoId: value?.id || null,
              })
            }
          />
          <TextField
            type="date"
            fullWidth
            required
            value={newDonation.data}
            onChange={(e) =>
              setNewDonation({ ...newDonation, data: e.target.value })
            }
            sx={{ mb: 2, mt: 2 }}
          />
          <OfferingInput
            label={t("Amount")}
            value={newDonation.valor}
            onChange={(value) =>
              setNewDonation({ ...newDonation, valor: value })
            }
          />
          <FormControl fullWidth required>
            <InputLabel id="offering-type-label">
              {t("Offering Type")}
            </InputLabel>
            <Select
              label={t("Offering Type")}
              value={newDonation.tipo}
              onChange={(e) =>
                setNewDonation({ ...newDonation, tipo: e.target.value })
              }
            >
              {donationTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{t("Cancel")}</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDonation}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
