import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useTreasuryQuery,
  ensureTreasuryQueryData,
} from "data/queries/treasury/queryTreasury";
import fetch from "data/fetch";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

export async function loader({ params }) {
  const { id } = params;
  if (!id) {
    throw new Error("Invalid ID provided.");
  }
  return ensureTreasuryQueryData(id);
}

export default function TreasuryIndexPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: queryData } = useTreasuryQuery({ id });
  const treasuryData = queryData?.data;

  const handleDelete = async (entryId) => {
    if (!window.confirm(t("Are you sure you want to delete this entry?"))) {
      return;
    }
    try {
      await fetch(`/v1/relatorio-culto-tesouraria/${entryId}`, {
        method: "DELETE",
      });
      await ensureTreasuryQueryData(id); // Refresh data after deletion
    } catch (error) {
      alert(t("Failed to delete the entry. Please try again."));
    }
  };

  if (!treasuryData || treasuryData.length === 0) {
    return (
      <Container>
        <DetailsPageHeader
          heading={t("Treasury Report")}
          label={t("Report Details")}
          routeIndex="/treasury"
        />
        <Alert severity="info">
          <Stack spacing={2}>
            <div>{t("No treasury data found.")}</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/treasury/${id}/new`)}
            >
              {t("Start New Treasury Report")}
            </Button>
          </Stack>
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <DetailsPageHeader
        heading={t("Treasury Report")}
        label={t("Report Details")}
        routeIndex="/treasury"
      />

      {treasuryData && (
        <Stack spacing={4}>
          <FormCard title={t("Details")}>
            <dl>
              <dt>{t("Congregation")}</dt>
              <dd>
                {treasuryData[0]?.relatorioCultoSecretaria.congregacao.name ??
                  t("N/A")}
              </dd>

              <dt>{t("Report Date")}</dt>
              <dd>
                {moment(treasuryData[0]?.relatorioCultoSecretaria.data).format(
                  "MMM/DD/YYYY"
                )}
              </dd>

              <dt>{t("Service Type")}</dt>
              <dd>
                {t(treasuryData[0]?.relatorioCultoSecretaria.tipoCulto) ??
                  t("N/A")}
              </dd>
            </dl>
          </FormCard>
          <FinancialTable data={treasuryData} onDelete={handleDelete} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/treasury/${id}/new`)}
            sx={{ alignSelf: "flex-start" }}
          >
            {t("Add Extra Records")}
          </Button>
        </Stack>
      )}
    </Container>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

function FinancialTable({ data, onDelete }) {
  const { t } = useTranslation();

  // Calculate totals for each column
  const totals = {
    oferta: data.reduce((sum, entry) => sum + (entry.oferta || 0), 0),
    dizimo: data.reduce((sum, entry) => sum + (entry.dizimo || 0), 0),
    ofertaMissao: data.reduce(
      (sum, entry) => sum + (entry.ofertaMissao || 0),
      0
    ),
    ofertaEBD: data.reduce((sum, entry) => sum + (entry.ofertaEBD || 0), 0),
    ofertaFC: data.reduce((sum, entry) => sum + (entry.ofertaFC || 0), 0),
    ofertaPrimicias: data.reduce((sum, entry) => sum + (entry.ofertaPrimicias || 0), 0),
    ofertaOutras: data.reduce((sum, entry) => sum + (entry.ofertaOutras || 0), 0),
  };

  // Calculate subtotal
  const subtotal = Object.values(totals).reduce((sum, value) => sum + value, 0);

  return (
    <Card>
      <CardHeader title={t("Financial Details")} />

      <CardContent as={Stack} spacing={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Offering")}</TableCell>
                <TableCell>{t("Tithe")}</TableCell>
                <TableCell>{t("Mission Offering")}</TableCell>
                <TableCell>{t("EBD Offering")}</TableCell>
                <TableCell>{t("FC Offering")}</TableCell>
                <TableCell>{t("Firstfruits Offering")}</TableCell>
                <TableCell>{t("Other Offerings")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.pessoa?.name ?? t("Anonymous")}</TableCell>
                  <TableCell>
                    {moment(entry.relatorioCultoSecretaria?.data).format(
                      "MMM/DD/YYYY"
                    ) ?? t("N/A")}
                  </TableCell>
                  <TableCell>{entry.oferta ?? t("N/A")}</TableCell>
                  <TableCell>{entry.dizimo ?? t("N/A")}</TableCell>
                  <TableCell>{entry.ofertaMissao ?? t("N/A")}</TableCell>
                  <TableCell>{entry.ofertaEBD ?? t("N/A")}</TableCell>
                  <TableCell>{entry.ofertaFC ?? t("N/A")}</TableCell>
                  <TableCell>{entry.ofertaPrimicias ?? t("N/A")}</TableCell>
                  <TableCell>{entry.ofertaOutras ?? t("N/A")}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label={t("Delete")}
                      onClick={() => onDelete(entry.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {/* Total Row */}
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                  {t("Total")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.oferta}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.dizimo}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.ofertaMissao}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.ofertaEBD}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.ofertaFC}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.ofertaPrimicias}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{totals.ofertaOutras}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Subtotal Display */}
        <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
          {t("Subtotal")}: {subtotal}
        </Typography>
      </CardContent>
    </Card>
  );
}
