import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import ViewportAvatarButton from "./ViewportAvatarButton.react";
import ViewportLanguageSelector from "./ViewportLanguageSelector.react";

export default function ViewportAppBar({ toggleDrawer }) {
  return (
    <AppBar
      color="transparent"
      component="header"
      position="fixed"
      sx={styles.appBar}
    >
      <Toolbar sx={styles.toolbar}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { lg: "none" } }}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          alignItems="center"
          direction="row"
          flexGrow={1}
          justifyContent="flex-end"
          spacing={{ sm: 1, xs: 0.5 }}
        >
          <ViewportLanguageSelector />
          <ViewportAvatarButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

const styles = {
  appBar: {
    boxShadow: "none",
    height: 80,
    zIndex: 1101,
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(250, 250, 250, 0.8)",
    transition: "height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  toolbar: {
    height: "100%",
    px: { lg: 5, sm: 3 },
  },
};
