import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidatePeopleQuery } from "data/queries/queryPeople";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import fetch from "data/fetch";
import MemberForm from "./components/MemberForm.react";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function MemberNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  // TODO move to mutations
  const onSubmit = async ({
    name,
    email,
    phone,
    gender,
    status,
    congregationID,
    preferredContactMethod,
    birthDate,
    address,
    maritalStatus,
    marriageDate,
    waterBaptismAt,
    spiritBaptismAt,
    creationStatus,
    type,
    originatingChurch,
    churchDepartment,
    spouseName,
    spouseBirthdate,
    spouseIsMember,
    childrenInfo,
    photo,
    roles,
    documents,
  }) => {
    setErrorMessage(null);

    const familyMembers = [];

    if (spouseName) {
      familyMembers.push({
        name: spouseName,
        birthDate: spouseBirthdate,
        isMember: spouseIsMember,
        type: "SPOUSE",
      });
    }

    const filteredChildrenInfo = childrenInfo.filter(
      (c) => c.name !== "" && c.birthDate !== ""
    );

    if (filteredChildrenInfo.length) {
      filteredChildrenInfo.forEach((child) => (child.type = "CHILD"));
      familyMembers.push(...filteredChildrenInfo);
    }

    let photoFileId;

    if (photo) {
      try {
        const photoData = new FormData();
        photoData.append("file", photo);
        photoData.append("name", photo.name);
        photoData.append("mime", photo.type);

        const photoResponse = await fetch(`/v1/file`, {
          method: "POST",
          body: photoData,
        });

        photoFileId = photoResponse.id;
      } catch (e) {
        setErrorMessage(
          e.apiMessage ??
            "Sorry, there was an error uploading your personal photo."
        );
        return;
      }
    }

    let documentFileIds = [];
    try {
      // TODO: parallelize
      for (const doc of documents) {
        if (!doc.file) {
          continue;
        }

        const docData = new FormData();
        docData.append("file", doc.file);
        docData.append("name", doc.file.name);
        docData.append("mime", doc.file.type);

        const docResponse = await fetch(`/v1/file`, {
          method: "POST",
          body: docData,
        });

        documentFileIds.push({
          fileId: docResponse.id,
          type: doc.type,
        });
      }
    } catch (e) {
      setErrorMessage(
        e.apiMessage ?? "Sorry, there was an error uploading your document."
      );
      return;
    }

    try {
      await fetch(`/v1/pessoa`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          congregacaoId: congregationID,
          prefContact: preferredContactMethod,
          creationStatus,
          birthDate,
          tipo: type,
          originatingChurch,
          departments: churchDepartment,
          maritalStatus,
          waterBaptismAt,
          spiritBaptismAt,
          marriageAt: marriageDate,
          address: address,
          familyMembers,
          documents: [
            photoFileId
              ? {
                  fileId: photoFileId,
                  type: "OFFICIAL_PHOTO",
                }
              : null,
          ]
            .concat(documentFileIds)
            .filter(Boolean),
        }),
      });

      invalidatePeopleQuery();
      navigate("/members");
    } catch (e) {
      setErrorMessage(
        e.apiMessage ?? "Sorry, an unexpected error has occurred."
      );
    }
  };

  return (
    <MemberForm
      onSubmit={onSubmit}
      error={errorMessage}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new member")}
          links={[
            {
              name: t("Members"),
              to: "/members",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
