import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * Add any new strings into this object.
 *  - add to en with key + value being the exact same string
 *  - add to pt_BR and translate
 *  - take en and translate into `es` using ChatGPT
 *
 * ChatGTP does a good job https://chat.openai.com/ with the query:
 * "translate these strings to Spanish and keep the same output format:"
 */
// prettier-ignore
const STRINGS = {
  en: {
    translation: {
      "Add Guests": "Add Guests",
      "Congregation is required": "Congregation is required",
      "Email is required": "Email is required",
      "Find nearest congregation": "Find nearest congregation",
      "Guest Registration": "Guest Registration",
      "Invalid login": "Invalid login",
      "Name is required": "Name is required",
      "Guest added successfully.": "Guest added successfully.",
      "Phone is required": "Phone is required",
      "Preferred contact method": "Preferred contact method",
      "Preferred contact method:": "Preferred contact method:",
      "Registering...": "Registering...",
      "Remember me": "Remember me",
      "Sorry, there was an issue. Please try again.":
        "Sorry, there was an issue. Please try again.",
      "Thank you!": "Thank you!",
      "Clear search": "Clear search",
      Congregation: "Congregation",
      "Create a new member": "Create a new member",
      Delete: "Delete",
      Edit: "Edit",
      Email: "Email",
      Leader: "Leader",
      List: "List",
      Members: "Members",
      "My Account": "Minha Conta",
      New: "New",
      "New Caring Group": "New Caring Group",
      "New member": "New member",
      Name: "Name",
      Password: "Password",
      Phone: "Phone",
      Register: "Register",
      "Rows per page:": "Rows per page:",
      "Oops!": "Oops!",
      Search: "Search",
      "Sorry, an unexpected error has occurred.":
        "Sorry, an unexpected error has occurred.",
      "Page not found.": "Page not found.",
      Single: "Single",
      Married: "Married",
      Divorced: "Divorced",
      Widowed: "Widowed",
      "LIDER_ADOLESCENTES": "Adolescents Leader",
      "LIDER_CARING_GROUP": "Caring Group Leader",
      "LIDER_CORAL": "Choir Leader",
      "LIDER_CRIANCAS": "Children Leader",
      "LIDER_DIACONOS": "Deacons Leader",
      "LIDER_ESCOLA_DOMINICAL": "Sunday School Leader",
      "LIDER_EVENTOS": "Events Leader",
      "LIDER_FAMILIA_CRISTA": "Christian Family Leader",
      "LIDER_GRUPO_DE_LOUVOR": "Worship Group Leader",
      "LIDER_JOVENS": "Youth Leader",
      "LIDER_JOVENS_CASAIS": "Young Couples Leader",
      "LIDER_JUNIORES": "Juniors Leader",
      "LIDER_MIDIA": "Media Leader",
      "LIDER_MISSAO": "Mission Leader",
      "LIDER_MULHERES_DE_FE": "Women of Faith Leader",
      "LIDER_OBREIROS": "Workers Leader",
      "LIDER_ORQUESTRA": "Orchestra Leader",
      "PASTOR_LOCAL": "Local Pastor",
      "PASTOR_REGIONAL": "Regional Pastor",
      "SECRETARIO": "Secretary",
      "SYSTEM_ADMIN": "System Admin",
      "TESOUREIRO": "Treasurer",
      "Select permissions": "Select permissions",
      "Permissions": "Permissions",
      "Visitation Date": "Data da Visita",

    },
  },
  pt_BR: {
    translation: {
      "Caring Groups Reports": "Relatórios de Caring Groups",
       "Status": "Situação",
      "Comment": "Comentário",
      "Present": "Presente",
      "Absent": "Ausente",
      "Work": "Trabalho",
      "Sick": "Enfermidade",
      "Travel": "Viagem",
      "Report date": "Data do Relatório",
      "ADOLESCENTES": "Adolescentes", 
      "CAVALHEIROS": "Cavalheiros", 
      "CORAL": "Coral", 
      "DIACONOS": "Diáconos", 
      "GRUPO_DE_LOUVOR": "Grupo de Louvor", 
      "JOVENS": "Jovens", 
      "JOVENS_CASAIS": "Jovens Casais", 
      "JUNIORES": "Júniores", 
      "MULHERES_DE_FE": "Mulheres de Fé", 
      "ORQUESTRA": "Orquestra", 
      "OBREIROS": "Obreiros", 
      "Department": "Departamento",
      "Role": "Função",
      "All documents must have an associated type": "Todos documentos precisam de um tipo associado.",
      'Also a member?': 'Também é membro?',
      "Type": "Tipo",
      "Yes": "Sim",
      "No": "Não",
      "Spouse": "Cônjuge",
      "Child": "Filho(a)",
      "Document file format is invalid.": "Formato do arquivo é inválido",
      "Marital Status is required": "Estado Civil é obrigatório",
      "Photo file format is invalid.": "Formato do arquivo de foto é inválido.",
      "Sorry, there was an error uploading your personal photo.": "Desculpe, mas houve um error salvando sua foto pessoal.",
      "Personal Photo": "Foto Pessoal",
      "Membership Method": "Método de Membresia",
      "Membership Method is required": "Método de Membresia é obrigatório.",
      "Leader is required": "Líder é obrigatório",
      "Select at least one member.": "Selecione pelo menos um membro.",
      "Add Guests": "Registrar Convidado",
      "Add a member": "Adicione um membro",
      "Congregation is required": "Congregação é obrigatório",
      "Clear search": "Limpar busca",
      "Date of birth": "Data de nascimento",
      "Date of birth is required": "Data de nascimento é obrigatória",
      "Spouse name and birthdate are required":
        "Nome e data de nascimento do cônjuge são obrigatórios.",
      "Child's name and birthdate are required":
        "Nome e data de nascimento dos filhos(as) são obrigatórios.",
      "Full address is required": "Endereço completo é obrigatório",
      "Baptism Date is required": "Data de Batismo é obrigatória",
      "Email is required": "Email é obrigatório",
      "Find nearest congregation": "Localizar a congregação mais próxima",
      "Guest Registration": "Registro de Convidado",
      "Invalid login": "Login inválido",
      "Name is required": "Nome é obrigatório",
      "Guest added successfully.": "Convidado adicionado com sucesso.",
      "Phone is required": "Fone é obrigatório",
      "Department is required": "Departamento é obrigatório",
      "Preferred contact method": "Preferência para contato",
      "Preferred contact method:": "Preferência para contato:",
      "Registering...": "Registrando...",
      "Remember me": "Ficar conectado",
      "Sorry, there was an issue. Please try again.":
        "Desculpe, houve um problem. Por favor tente novamente.",
      "Thank you!": "Obrigado!",
      "Complete your registration": "Complete seu cadastro",
      Congregation: "Congregação",
      "Create a new member": "Cadastrar um novo membro",
      Delete: "Remover",
      Edit: "Editar",
      Email: "Email",
      Address: "Endereço",
      Zipcode: "Código Postal",
      City: "Cidade",
      Country: "País",
      Leader: "Líder",
      List: "Lista",
      "Marital Status": "Estado Civil",
      Members: "Membros",
      Name: "Nome",
      New: "Novo",
      "New Caring Group": "Novo Caring Group",
      "New member": "Novo membro",
      "New Report": "Novo Relatório",
      Password: "Senha",
      Person: "Pessoa",
      Phone: "Fone",
      Photo: "Foto",
      Relation: 'Relação', 
      Register: "Registrar",
      Reports: "Relatórios",
      "Rows per page:": "Registros por página:",
      "Oops!": "Oops!",
      Search: "Buscar",
      "Sorry, an unexpected error has occurred.":
        "Desculpe, ocorreu um error inesperado.",
      "Page not found.": "Página não encontrada.",
      Single: "Solteiro(a)",
      Submit: "Salvar",
      "Submitting...": "Salvando...",
      Married: "Casado(a)",
      Divorced: "Divorciado(a)",
      Widowed: "Viúvo(a)",
      "Child's Name": "Nome do(a) Filho(a)",
      "Child's Date of Birth": "Data de Nascimento do(a) Filho(a)",
      "Child is also a member?": "Filho(a) também é membro?",
      "Remove Child": "Remover Filho(a)",
      "Add Child": "Adicionar Filho(a)",
      "Marriage Date": "Data de Casamento",
      "Selected Members": "Memberos Selecionados",
      "Spouse's Name": "Nome do cônjuge",
      "Spouse's Date of Birth": "Data de nascimento do cônjuge",
      "Spouse is also a member?": "Cônjuge também é membro?",
      "Has children?": "Possui filhos?",
      "Name and Age of Children": "Nome e idade dos filhos",
      "Baptism Date": "Data do Batismo nas águas?",
      "Holy Spirit Baptism Date": "Data do Batismo no Espírito Santo?",
      "Worker?": "Obreiro?",
      "Origin Church": "Igreja de Origem",
      "Which department do you participate or would like to participate in?":
        "Qual departamento participa ou deseja participar?",
      'No results found for "{{filterName}}".':
        'Nenhum resultado encontrado com "{{filterName}}".',
      "Edit caring group": "Editar caring group",
      "Deleting...": "Removendo...",
      "Do you confirm the deletion?": "Você confirma a remoção?",
      "Caring Groups already has 5 people": "Caring Groups já tem 5 pessoas",
      "Member already on a caring group": "Membro já está em caring group",
      "Member and leader can not be the same":
        "Membro e Líder não podem ser o mesmo",
      "Add new": "Adicionar novo",
      "New Christian Family Group":"Nova Família Cristã",
      "Christian Family":"Família Cristã",
      "Create a new Christian Family Group":"Criar nova Família Cristã",
      "Edit Christian Family":"Editar Família Cristã",
      "CONVIDADO": "Convidado",
      "CONGREGADO": "Congregado",
      "MEMBRO": "Membro",
      "COOPERADOR": "Cooperador",
      "LIDER": "Líder",
      "DIACONO": "Diácono",
      "PRESBITERO": "Presbítero",
      "EVANGELISTA": "Evangelista",
      "PASTOR": "Pastor",
      "Offering": "Oferta",
      "Number of Guests": "Número de Convidados",
      "Study ID": "ID do Estudo",
      "Tithe": "Dízimo",
      "Special Offering": "Oferta Especial",
      "Mission Offering": "Oferta de Missão",
      "Songs": "Hinos",
      "Healing": "Curas",
      "Miracles": "Milagres",
      "Conversions": "Conversões",
      "Message": "Mensagem",
      "File of the week": "Estudo da Semana",
      "Upload File": "Upload do Arquivo",
      "Analytics": "Analítico",
      "Christian Family Reports": "Relatório Família Cristã",
      "Description": "Descrição",
      "File ID": "ID do Arquivo",
      "Upload New File": "Upload de Novo Arquivo",
      "Choose File": "Escolher Arquivo",
      "LIDER_ADOLESCENTES": "Líder Adolescentes",
      "LIDER_CARING_GROUP": "Líder Caring Group",
      "LIDER_CORAL": "Líder Coral",
      "LIDER_CRIANCAS": "Líder Crianças",
      "LIDER_DIACONOS": "Líder Diáconos",
      "LIDER_ESCOLA_DOMINICAL": "Líder Escola Dominical",
      "LIDER_EVENTOS": "Líder Eventos",
      "LIDER_FAMILIA_CRISTA": "Líder Família Cristã",
      "LIDER_GRUPO_DE_LOUVOR": "Líder Grupo De Louvor",
      "LIDER_JOVENS": "Líder Jovens",
      "LIDER_JOVENS_CASAIS": "Líder Jovens Casais",
      "LIDER_JUNIORES": "Líder Juniores",
      "LIDER_MIDIA": "Líder Mídia",
      "LIDER_MISSAO": "Líder Missão",
      "LIDER_MULHERES_DE_FE": "Líder Mulheres De Fé",
      "LIDER_OBREIROS": "Líder Obreiros",
      "LIDER_ORQUESTRA": "Líder Orquestra",
      "PASTOR_LOCAL": "Pastor Local",
      "PASTOR_REGIONAL": "Pastor Regional",
      "SECRETARIO": "Secretário",
      "SYSTEM_ADMIN": "System Admin",
      "TESOUREIRO": "Tesoureiro",
      "Select permissions": "Selecione as Permissões",
      "Permissions": "Permissões",
      "Regionals": "Regionais",
      "New Regional": "Nova Regional",
      "Congregations": "Congregações",
      "Edit Congregation": "Editar Congregação",
      "Guests": "Convidados",
      "Service Secretary": "Culto Secretaria",
      "Date": "Data",
      "Guests (Non-Believers)": "Convidados (Não Crentes)",
      "Accepted/Returned": "Aceitaram/Voltaram",
      "Adults": "Adultos",
      "Children": "Crianças",
      "Guests (Believers)": "Convidados (Crentes)",
      "Baptisms": "Batismos",
      "Holy Spirit Baptisms": "Batismos no Espírito Santo",
      "Transfers": "Transferências",
      "Received Members": "Membros Recebidos",
      "Create a new service secretary report": "Criar um novo relatório de secretaria de culto",
      "Service Type": "Tipo de Culto",
      "Observations": "Observações",
      "ENSINO": "Ensino",
      "CELEBRACAO": "Celebração",
      "SANTA_CEIA": "Santa Ceia",
      "FRIENDS_4EVER": "Friends 4Ever",
      "CONGRESSO": "Congresso",
      "ANIVERSARIO_DA_IGREJA": "Aniversário da Igreja",
      "SANTA_CEIA_REGIONAL": "Santa Ceia Regional",
      "Treasury": "Tesouraria",
      "Treasury Report": "Relatório de Tesouraria",
      "Report Details": "Detalhes do Relatório",
      "Details": "Detalhes",
      "Report Date": "Data do Relatório",
      "Financial Details": "Detalhes Financeiros",
      "EBD Offering": "Oferta da EBD",
      "Firstfruits Offering": "Oferta de Primícias",
      "Other Offerings": "Outras Ofertas",
      "Actions": "Ações",
      "Add Extra Records": "Adicionar Novos Registros",
      "No treasury data found.": "Nenhum dado de tesouraria encontrado.",
      "Start New Treasury Report": "Iniciar Novo Relatório de Tesouraria",
      "Create a new treasury report": "Criar um novo relatório de tesouraria",
      "Treasury List": "Lista de Tesouraria",
      "Christian Family Offering": "Oferta da Família Cristã",
      "Add New Registry": "Adicionar Novo Registro",
      "Save": "Salvar",
      "Are you sure you want to delete this entry?": "Tem certeza de que deseja excluir este registro?",
      "Anonymous Person": "Pessoa Anônima",
      "Please select a person.": "Por favor, selecione uma pessoa.",
      "Search by Congregation": "Pesquisar por Congregação",
      "Search by Date (e.g., Nov/24/2024)": "Pesquisar por Data (ex.: Nov/24/2024)",
      "Search by Service Type": "Pesquisar por Tipo de Culto",
      "Contact Information": "Dados de Contato",
      "Preferred Contact Method": "Método de Contato Preferido",
      "Personal Information": "Dados Pessoais",
      "Postal Code": "Código Postal",
      "Date of Birth": "Data de Nascimento",
      "Family Information": "Dados Familiares",
      "Congregational Information": "Dados Congregacionais",
      "Profile Updated": "Perfil Atualizado",
      "Your profile has been updated successfully.": "Seu perfil foi atualizado com sucesso.",
      "Haiti": "Haiti",
      "Denmark": "Dinamarca",
      "Portugal": "Portugal",
      "Spain": "Espanha",
      "United States": "Estados Unidos",
      "France": "França",
      "Italy": "Itália",
      "New Zealand": "Nova Zelândia",
      "United Kingdom": "Reino Unido",
      "Australia": "Austrália",
      "Belgium": "Bélgica",
      "Germany": "Alemanha",
      "Switzerland": "Suíça",
      "Netherlands": "Holanda",
      "Brazil": "Brasil",
      "FAMILIA": "Família",
      "MISSOES_E_FRIENDS_4EVER": "Missões e Friends4Ever",
      "OUTRO": "Outro",
      "State": "Estado",
      "Member Status": "Status do Membro",
      "Active": "Ativo",
      "Inactive": "Inativo",
      "Deceased": "Falecido",
      "Donation": "Doação",
      "File Of The Week": "Estudo da Semana",
      "Welcome back, {{name}}!": "Bem-vindo(a) de volta, {{name}}!",
      "Quick Links": "Links Rápidos",
      "Don't forget to finish setting up your profile!": "Não se esqueça de completar o seu perfil!",
      "Go to Profile": "Ir para o Perfil",
      "Progress: {{points}} XP": "Progresso: {{points}} XP",
      "Familia Crista": "Família Cristã",
      "Caring Groups": "Grupos de Apoio",
      "File of the Week": "Estudo da Semana",
      "Profile": "Perfil",
      "Donations": "Doações",
      "Donation List": "Lista de Doações",
      "New Donation": "Nova Doação",
      "Person Name": "Nome da Pessoa",
      "Amount": "Valor",
      "No donations found.": "Nenhuma doação encontrada.",
      "Loading...": "Carregando...",
      "Anonymous": "Anônimo",
      "Add New Donation": "Adicionar Nova Doação",
      "Offering Type": "Tipo de Oferta",
      "All fields are required.": "Todos os campos são obrigatórios.",
      "Failed to load donations.": "Falha ao carregar doações.",
      "Failed to add donation.": "Falha ao adicionar doação.",
      "Failed to delete donation.": "Falha ao deletar doação.",
      "Are you sure you want to delete this donation?": "Tem certeza de que deseja excluir esta doação?",
      "Confirm Deletion": "Confirmar Exclusão",
      "Cancel": "Cancelar",
      "Welcome back": "Bem-vindo(a) de volta",
      "User": "Usuário",
      "Here are your quick actions and reminders.": "Aqui estão suas ações rápidas e lembretes.",
      "Profile Completion:": "Progresso do Perfil:",
      "XP": "XP",
      "Quick Access": "Acesso Rápido",
      "Família Cristã": "Família Cristã",
      "Access Família Cristã resources.": "Acesse recursos da Família Cristã.",
      "Manage and join caring groups.": "Gerencie e participe de grupos de apoio.",
      "Download the file of the week.": "Baixe o estudo da semana.",
      "Complete your profile.": "Complete o seu perfil.",
      "Go": "Acessar",
      "DIZIMO": "Dízimo",
      "OFERTA": "Oferta",
      "MISSOES": "Missões",
      "VOTO": "Voto",
      "OUTROS": "Outros",
      "FC Offering": "Oferta FC",
      "Holy Spirit Baptism Date is required for this role.": "A data do Batismo com o Espírito Santo é obrigatória para esta função.",
    },
  },
  es: {
    translation: {
      "Add Guests": "Agregar Invitados",
      "Congregation is required": "Se requiere Congregación",
      "Email is required": "Se requiere Correo electrónico",
      "Find nearest congregation": "Encontrar congregación más cercana",
      "Guest Registration": "Registro de Invitados",
      "Invalid login": "Inicio de sesión inválido",
      "Name is required": "Se requiere Nombre",
      "Guest added successfully.": "Invitado agregado exitosamente.",
      "Phone is required": "Se requiere Teléfono",
      "Preferred contact method:": "Método de contacto preferido:",
      "Registering...": "Registrando...",
      "Remember me": "Recordarme",
      "Sorry, there was an issue. Please try again.":
        "Lo sentimos, hubo un problema. Por favor, inténtelo nuevamente.",
      "Thank you!": "¡Gracias!",
      Congregation: "Congregación",
      Email: "Correo electrónico",
      Name: "Nombre",
      Password: "Contraseña",
      Phone: "Teléfono",
      Register: "Registrar",
      "New Christian Family Group":"Nueva Familia Cristiana",
      "Christian Family":"Familia Cristiana",
      "Create a new Christian Family Group":"Crear una nueva Familia Cristiana",
      "Edit Christian Family":"Editar Familia Cristiana",
    },
  },
};

export default function setupTranslation() {
  i18n.use(initReactI18next).init({
    lng: "pt_BR",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: STRINGS,
  });
}
