import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCultoSecretarias(page = 0) {
  return fetch(`/v1/relatorio-culto-secretaria?size=100&index=${page}`);
}

const getQueryKey = (index = 0) => ["culto-secretarias", index];

export function useCultoSecretariasQuery({ page = 0, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page),
    queryFn: () => fetchCultoSecretarias(page),
  });
}

export function ensureCultoSecretariasQueryData({ page = 0 } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page),
    queryFn: () => fetchCultoSecretarias(page),
  });
}

export function invalidateCultoSecretariasQuery() {
  return queryClient.invalidateQueries(["culto-secretarias"]);
}
