import { useId, useState, Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
// import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import { formatPhone, isPhoneValid } from "utils/PhoneValidator";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import moment from "moment";
import { isEmailValid } from "utils/EmailValidator";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import DepartmentSelect from "shared/components/DepartmentSelect/DepartmentSelectMulti.react";
import RoleSelect from "shared/components/RoleSelect/RoleSelect.react";

import { MuiTelInput } from "mui-tel-input";
import { CountrySelector } from "shared/components/CountrySelector/CountrySelector.react";

const MemberStatus = {
  ACTIVE: "ATIVO",
  INACTIVE: "INATIVO",
  DECEASED: "FALECIDO",
};

const Gender = {
  MALE: "MASCULINO",
  FEMALE: "FEMININO",
};

const MaritalStatus = {
  SINGLE: "SINGLE",
  MARRIED: "MARRIED",
  DIVORCED: "DIVORCED",
  WIDOWED: "WIDOWED",
};

const DocumentType = {
  OFFICIAL_PHOTO: "OFFICIAL_PHOTO",
  OFFICIAL_DOCUMENT: "OFFICIAL_DOCUMENT",
  MARRIAGE_DOCUMENT: "MARRIAGE_DOCUMENT",
  COURSE_CERTIFICATE: "COURSE_CERTIFICATE",
  THEOLOGY_CERTIFICATE: "THEOLOGY_CERTIFICATE",
  TRANSFERRED_LETTER: "TRANSFERRED_LETTER",
  OTHER: "OTHER",
};

const CHILDREN_INITIAL_DATA = {
  name: "",
  birthDate: null,
  isMember: false,
  type: "CHILD",
};
const CHILDRAN_INITIAL_ARRAY = [CHILDREN_INITIAL_DATA];

const PHOTO_ALLOWED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
const DOCUMENT_ALLOWED_FORMATS = [...PHOTO_ALLOWED_FORMATS, "application/pdf"];

let globalFileIndex = 0;
const DOCUMENTS_INITIAL_DATA = [
  {
    clientId: `document_${++globalFileIndex}`,
  },
  {
    clientId: `document_${++globalFileIndex}`,
  },
  {
    clientId: `document_${++globalFileIndex}`,
  },
];

export default function MemberForm({
  breadcrumbs,
  member,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [congregation, setCongregation] = useState(member?.congregacao);
  const [name, setName] = useState(member?.name);
  const [email, setEmail] = useState(member?.email);
  const [phone, setPhone] = useState(member?.phone);
  const [gender, setGender] = useState(member?.gender || Gender.MALE);
  const [preferredContactMethod, setPreferredContactMethod] = useState(
    member?.prefContact ?? "EMAIL"
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [birthdate, setBirthdate] = useState(maybeDate(member?.birthDate));
  const [addressStreet, setAddressStreet] = useState(member?.address?.street);
  const [addressPostCode, setAddressPostCode] = useState(
    member?.address?.postCode
  );
  const [addressCity, setAddressCity] = useState(member?.address?.city);
  const [addressState, setAddressState] = useState(
    member?.address?.state || ""
  );
  const [addressCountry, setAddressCountry] = useState(
    member?.address?.country
  );
  const [maritalStatus, setMaritalStatus] = useState(member?.maritalStatus);
  const [marriageDate, setMarriageDate] = useState(
    maybeDate(member?.marriageAt)
  );
  const [status, setStatus] = useState(member?.status || MemberStatus.ACTIVE);

  const [
    spouseId,
    spouseName,
    spouseIsMember,
    setSpouseName,
    spouseBirthdate,
    childrenInfo,
    setSpouseIsMember,
    setSpouseBirthdate,
    setChildrenInfo,
  ] = useFamilyMembersState(member);
  const isMarried = maritalStatus === MaritalStatus.MARRIED;
  const [waterBaptismAt, setWaterBaptismDate] = useState(
    maybeDate(member?.waterBaptismAt)
  );
  const [spiritBaptismAt, setSpiritBaptismAt] = useState(
    maybeDate(member?.spiritBaptismAt)
  );
  const [creationStatus, setCreationStatus] = useState(member?.creationStatus);
  const [type, setType] = useState(member?.tipo ?? "MEMBRO");
  const [roles, setRoles] = useState(member?.roles || []);
  const [originatingChurch, setOriginatingChurch] = useState(
    member?.originatingChurch
  );
  const [churchDepartment, setChurchDepartment] = useState(
    member?.departments ?? []
  );
  const [documents, photo, setDocuments, setPhoto] = useDocumentsState(member);
  const requireMoreData = type !== "CONVIDADO" && type !== "CONGREGADO";

  const requiredTypes = ["DIACONO", "PRESBITERO", "EVANGELISTA", "PASTOR"];

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (requiredTypes.includes(type) && isNullOrEmpty(spiritBaptismAt)) {
      setErrorMessage(t("Holy Spirit Baptism Date is required for this role."));
      return;
    }

    const congregationID = congregation?.id ?? "";

    if (congregationID == null || congregationID === "") {
      setErrorMessage(t("Congregation is required"));
      return;
    }

    if (name === "") {
      setErrorMessage(t("Name is required"));
      return;
    }

    if (email === "") {
      setErrorMessage(t("Email is required"));
      return;
    }

    if (!isEmailValid(email)) {
      setErrorMessage(t("Email is invalid"));
      return;
    }

    if (phone === "") {
      setErrorMessage(t("Phone is required"));
      return;
    }

    if (!isPhoneValid(phone)) {
      setErrorMessage(t("Phone is invalid"));
      return;
    }

    if (requireMoreData && isNullOrEmpty(birthdate)) {
      setErrorMessage(t("Date of birth is required"));
      return;
    }

    if (requireMoreData && creationStatus === "") {
      setErrorMessage(t("Membership Method is required"));
      return;
    }

    if (requireMoreData && isNullOrEmpty(waterBaptismAt)) {
      setErrorMessage(t("Baptism Date is required"));
      return;
    }

    const address =
      addressStreet && addressPostCode && addressCity && addressCountry
        ? {
            street: addressStreet,
            postCode: addressPostCode,
            city: addressCity,
            state: addressState,
            country: addressCountry,
          }
        : null;

    if (
      requireMoreData &&
      isMarried &&
      (isNullOrEmpty(spouseName) || isNullOrEmpty(spouseBirthdate))
    ) {
      setErrorMessage(t("Spouse name and birthdate are required"));
      return;
    }

    for (const child of childrenInfo) {
      const hasName = !isNullOrEmpty(child.name);
      const hasBirthdate = !isNullOrEmpty(child.birthDate);

      if (hasName !== hasBirthdate) {
        setErrorMessage(t("Child's name and birthdate are required"));
        return;
      }
    }

    if (requireMoreData && address == null) {
      setErrorMessage(t("Full address is required"));
      return;
    }

    if (requireMoreData && maritalStatus == null) {
      setErrorMessage(t("Marital Status is required"));
      return;
    }

    if (requireMoreData && churchDepartment == null) {
      setErrorMessage(t("Department is required"));
      return;
    }

    if (requireMoreData && creationStatus == null) {
      setErrorMessage(t("Membership Method is required"));
      return;
    }

    const filteredDocuments = documents.filter((d) => !d.removed);

    if (
      filteredDocuments.find((d) => d.file != null && isNullOrEmpty(d.type))
    ) {
      setErrorMessage(t("All documents must have an associated type"));
      return;
    }

    setIsSubmitting(true);

    onSubmitProp({
      name,
      email,
      gender,
      status,
      phone: phone ? formatPhone(phone) : "",
      congregationID,
      preferredContactMethod,
      birthDate: birthdate ? moment(birthdate).format("YYYY-MM-DD") : "",
      address,
      maritalStatus,
      marriageDate: marriageDate
        ? moment(marriageDate).format("YYYY-MM-DD")
        : "",
      spouseId,
      spouseName,
      spouseBirthdate,
      spouseIsMember,
      childrenInfo,
      waterBaptismAt: waterBaptismAt
        ? moment(waterBaptismAt).format("YYYY-MM-DD")
        : "",
      spiritBaptismAt: spiritBaptismAt
        ? moment(spiritBaptismAt).format("YYYY-MM-DD")
        : "",
      creationStatus,
      type: type === "" ? "MEMBRO" : type,
      originatingChurch,
      roles,
      churchDepartment,
      photo,
      documents: filteredDocuments,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <>
      <Container>{breadcrumbs}</Container>
      <Container
        maxWidth="sm"
        component="form"
        method="post"
        onSubmit={onSubmit}
      >
        <Stack spacing={4}>
          <PersonTypeField value={type} onChange={setType} />
          {/* <Stack alignItems="center">
            <MemberAvatar name={name} photo={photo} />
          </Stack> */}
          <FormCard title="Dados de Contato">
            <CongregationSelect
              initialValue={congregation}
              required={true}
              onChange={setCongregation}
            />
            <NameField value={name} onChange={setName} />
            <FormControl>
              <FormLabel>{t("Gender")}</FormLabel>
              <RadioGroup
                row
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <FormControlLabel
                  value={Gender.MALE}
                  control={<Radio />}
                  label={t("Male")}
                />
                <FormControlLabel
                  value={Gender.FEMALE}
                  control={<Radio />}
                  label={t("Female")}
                />
              </RadioGroup>
            </FormControl>
            <EmailField value={email} onChange={setEmail} />
            <MuiTelInput
              required
              value={phone}
              label={t("Phone")}
              onChange={setPhone}
            />
            <PreferredContactMethodField
              value={preferredContactMethod}
              onChange={setPreferredContactMethod}
            />
          </FormCard>
          <FormCard title="Dados pessoais">
            <BirthdateField
              value={birthdate}
              onChange={setBirthdate}
              required={requireMoreData}
            />
            <AddressStreetField
              value={addressStreet}
              onChange={setAddressStreet}
              required={requireMoreData}
            />

            <AddressCity
              value={addressCity}
              onChange={setAddressCity}
              required={requireMoreData}
            />
            <TextField
              required={requireMoreData}
              label={t("State")}
              value={addressState}
              onChange={(e) => setAddressState(e.target.value)}
            />
            <AddressPostCode
              value={addressPostCode}
              onChange={setAddressPostCode}
              required={requireMoreData}
            />
            <CountrySelector
              value={addressCountry}
              onChange={setAddressCountry}
            />
            <MaritalStatusField
              value={maritalStatus}
              onChange={setMaritalStatus}
              required={requireMoreData}
            />
            {isMarried && (
              <MarriageDateField
                value={marriageDate}
                onChange={setMarriageDate}
              />
            )}
            <PhotoField onChange={setPhoto} />
          </FormCard>
          {/*TODO*/}
          <FormCard title="Dados familiares">
            {isMarried && (
              <>
                <SpouseNameField
                  value={spouseName}
                  onChange={setSpouseName}
                  required={requireMoreData}
                />
                <SpouseBirthdateField
                  value={spouseBirthdate}
                  onChange={setSpouseBirthdate}
                  required={requireMoreData}
                />
                <SpouseIsMemberField
                  value={spouseIsMember}
                  onChange={setSpouseIsMember}
                />
              </>
            )}
            <ChildrenInfoField
              value={childrenInfo}
              onChange={setChildrenInfo}
            />
          </FormCard>
          <FormCard title="Dados congregacionais">
            <BaptismDateField
              value={waterBaptismAt}
              onChange={setWaterBaptismDate}
              required={requireMoreData}
            />
            <BaptismHolySpiritDateField
              value={spiritBaptismAt}
              onChange={setSpiritBaptismAt}
              required={requiredTypes.includes(type)}
              error={
                requiredTypes.includes(type) && isNullOrEmpty(spiritBaptismAt)
              }
              helperText={
                requiredTypes.includes(type) && isNullOrEmpty(spiritBaptismAt)
                  ? t("Holy Spirit Baptism Date is required for this role.")
                  : ""
              }
            />
            <OriginatingChurchField
              value={originatingChurch}
              onChange={setOriginatingChurch}
            />
            <ChurchDepartmentField
              value={churchDepartment}
              onChange={setChurchDepartment}
              required={requireMoreData}
            />
            <CreationStatusField
              required={requireMoreData}
              value={creationStatus}
              onChange={setCreationStatus}
            />
          </FormCard>

          <FormCard title="Permissões">
            <RoleSelect value={roles} onChange={setRoles} />
          </FormCard>

          <FormCard title={t("Member Status")}>
            <FormControl>
              <InputLabel>{t("Status")}</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={MemberStatus.ACTIVE}>{t("Active")}</MenuItem>
                <MenuItem value={MemberStatus.INACTIVE}>
                  {t("Inactive")}
                </MenuItem>
                <MenuItem value={MemberStatus.DECEASED}>
                  {t("Deceased")}
                </MenuItem>
              </Select>
            </FormControl>
          </FormCard>

          <FormCard title="Documentos Adicionais">
            <Typography variant="subtitle1" gutterBottom>
              Certificados de conclusão de curso, Diplomas de Faculdade em
              Teologia, Carta de Mudança ou Transferência, e entre outros.
            </Typography>
            <DocumentRows documents={documents} onChange={setDocuments} />
          </FormCard>

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={member ? <CheckIcon /> : <AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
          {errorProp && <Alert severity="error">{t(errorProp)}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Stack>
      </Container>
    </>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent as={Stack} spacing={3}>
        {children}
      </CardContent>
    </Card>
  );
}

function useFamilyMembersState(member) {
  const { memberSpouse, memberChildren } = useMemo(() => {
    const shallowCopy = (obj) => ({ ...obj });
    const memberFamily =
      member?.familyMembers != null && member.familyMembers.length > 0
        ? member?.familyMembers.map(shallowCopy)
        : null;

    return {
      memberSpouse: memberFamily?.find((p) => p.type === "SPOUSE"),
      memberChildren: memberFamily?.filter((p) => p.type === "CHILD"),
    };
  }, [member]);

  const [spouseName, setSpouseName] = useState(memberSpouse?.name);
  const [spouseIsMember, setSpouseIsMember] = useState(
    memberSpouse?.isMember ?? false
  );
  const [spouseBirthdate, setSpouseBirthdate] = useState(
    maybeDate(memberSpouse?.birthDate)
  );
  const [childrenInfo, setChildrenInfo] = useState(
    memberChildren ?? CHILDRAN_INITIAL_ARRAY
  );

  return [
    memberSpouse?.id,
    spouseName,
    spouseIsMember,
    setSpouseName,
    spouseBirthdate,
    childrenInfo,
    setSpouseIsMember,
    setSpouseBirthdate,
    setChildrenInfo,
  ];
}

function getDocuments(member) {
  if (!member || !member.documents || member.documents.length === 0) {
    return DOCUMENTS_INITIAL_DATA;
  }

  const memberDocs = member.documents.map((d) => ({
    ...d,
    file: { ...d.file },
  }));

  memberDocs.push(DOCUMENTS_INITIAL_DATA[0]);

  if (memberDocs.length === 2) {
    memberDocs.push(DOCUMENTS_INITIAL_DATA[1]);
  }

  return memberDocs;
}
function useDocumentsState(member) {
  const initialData = useMemo(() => {
    const allDocs = getDocuments(member);
    return {
      docs: allDocs.filter((d) => d.type !== "OFFICIAL_PHOTO"),
      photo: allDocs.find((d) => d.type === "OFFICIAL_PHOTO"),
    };
  }, [member]);

  const [documents, setDocuments] = useState(initialData.docs);
  const [photo, setPhoto] = useState(initialData.photo);

  return [documents, photo, setDocuments, setPhoto];
}

// function MemberAvatar({ photo, name }) {
//   const { t } = useTranslation();
//   let props;
//   let sxOverride;

//   if (photo != null && photo !== "") {
//     props = {
//       alt: name || t("Photo"),
//       src: URL.createObjectURL(photo),
//     };
//   } else if (name != null && name !== "") {
//     sxOverride = { bgcolor: "primary.dark" };
//     props = {
//       children: name[0].toUpperCase(),
//     };
//   }

//   return <Avatar {...props} sx={[styles.avatar, sxOverride]} />;
// }

function NameField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={true}
      label={t("Name")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function EmailField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={true}
      label={t("Email")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function PreferredContactMethodField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel id="contact-label">{t("Preferred contact method:")}</FormLabel>
      <RadioGroup
        row={true}
        aria-labelledby="contact-label"
        name="contact-group"
        value={value}
        onChange={changeHandler(onChange)}
      >
        <FormControlLabel
          value="EMAIL"
          control={<Radio />}
          label={t("Email")}
        />
        <FormControlLabel
          value="PHONE"
          control={<Radio />}
          label={t("Phone")}
        />
      </RadioGroup>
    </FormControl>
  );
}

function BirthdateField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={required}
      label={t("Date of birth") + (required ? " *" : "")}
      value={value}
      onChange={onChange}
    />
  );
}

function AddressStreetField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={required}
      label={t("Address")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function AddressPostCode({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={required}
      label={t("Zipcode")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function AddressCity({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={required}
      label={t("City")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function MaritalStatusField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <FormControl required={required}>
      <InputLabel>{t("Marital Status")}</InputLabel>
      <Select value={value} onChange={changeHandler(onChange)}>
        <MenuItem value={MaritalStatus.SINGLE}>{t("Single")}</MenuItem>
        <MenuItem value={MaritalStatus.MARRIED}>{t("Married")}</MenuItem>
        <MenuItem value={MaritalStatus.DIVORCED}>{t("Divorced")}</MenuItem>
        <MenuItem value={MaritalStatus.WIDOWED}>{t("Widowed")}</MenuItem>
      </Select>
    </FormControl>
  );
}

function MarriageDateField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <DatePicker label={t("Marriage Date")} value={value} onChange={onChange} />
  );
}

function PhotoField({ onChange }) {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      onChange(null);
    } else if (!PHOTO_ALLOWED_FORMATS.includes(file.type)) {
      setError(t("Photo file format is invalid."));
      onChange(null);
    } else {
      setError("");
      onChange(file);
    }
  };

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      accept="image/*"
      type="file"
      label={t("Personal Photo")}
      onChange={handlePhotoUpload}
      helperText={error}
      error={error !== ""}
    />
  );
}

function BaptismDateField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={required}
      label={t("Baptism Date") + (required ? " *" : "")}
      value={value}
      onChange={onChange}
    />
  );
}

function BaptismHolySpiritDateField({
  value,
  onChange,
  required,
  error,
  helperText,
}) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={required}
      error={error}
      helperText={helperText}
      label={t("Holy Spirit Baptism Date") + (required ? " *" : "")}
      value={value}
      onChange={onChange}
    />
  );
}

function PersonTypeField({ value, onChange }) {
  const { t } = useTranslation();
  const labelId = useId();
  const label = t("Type");

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={value}
        label={label}
        onChange={changeHandler(onChange)}
      >
        <MenuItem value="CONVIDADO">{t("CONVIDADO")}</MenuItem>
        <MenuItem value="CONGREGADO">{t("CONGREGADO")}</MenuItem>
        <MenuItem value="MEMBRO">{t("MEMBRO")}</MenuItem>
        <MenuItem value="COOPERADOR">{t("COOPERADOR")}</MenuItem>
        <MenuItem value="LIDER">{t("LIDER")}</MenuItem>
        <MenuItem value="DIACONO">{t("DIACONO")}</MenuItem>
        <MenuItem value="PRESBITERO">{t("PRESBITERO")}</MenuItem>
        <MenuItem value="EVANGELISTA">{t("EVANGELISTA")}</MenuItem>
        <MenuItem value="PASTOR">{t("PASTOR")}</MenuItem>
      </Select>
    </FormControl>
  );
}

function OriginatingChurchField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      label={t("Origin Church")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function ChurchDepartmentField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <DepartmentSelect
      label={t(
        "Which department do you participate or would like to participate in?"
      )}
      required={required}
      value={value}
      onChange={onChange}
    />
  );
}

function SpouseNameField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <TextField
      required={required}
      label={t("Spouse's Name")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function SpouseBirthdateField({ value, onChange, required }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={required}
      label={t("Spouse's Date of Birth") + (required ? " *" : "")}
      value={value}
      onChange={onChange}
    />
  );
}

function SpouseIsMemberField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={() => onChange(!value)} />}
        label={t("Spouse is also a member?")}
      />
    </FormGroup>
  );
}

function ChildrenInfoField({ value, onChange }) {
  const { t } = useTranslation();
  const lastIndex = value.length - 1;

  const onAddChildrenClick = useCallback(() => {
    const newValue = [...value, { ...CHILDREN_INITIAL_DATA }];
    onChange(newValue);
  }, [onChange, value]);

  const onRemoveChildrenClick = useCallback(
    (removeIndex) => {
      const newValue = [...value];
      newValue.splice(removeIndex, 1);
      onChange(newValue);
    },
    [onChange, value]
  );

  const onChildChange = useCallback(
    (childData, childIndex) => {
      const newValue = [...value];
      newValue[childIndex] = childData;
      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <>
      {value.map((child, i) => (
        <Fragment key={i}>
          <ChildRow
            required={i !== 0}
            data={child}
            onChange={(childData) => onChildChange(childData, i)}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {i !== 0 && (
              <Button
                startIcon={<RemoveIcon />}
                variant="outline"
                size="medium"
                onClick={() => onRemoveChildrenClick(i)}
              >
                {t("Remove Child")}
              </Button>
            )}
            {i === lastIndex && (
              <Button
                startIcon={<AddIcon />}
                variant="outline"
                size="medium"
                onClick={onAddChildrenClick}
              >
                {t("Add Child")}
              </Button>
            )}
          </Stack>
        </Fragment>
      ))}
    </>
  );
}

function ChildRow({ required, data, onChange }) {
  const { t } = useTranslation();
  const areFieldsRequired = required || data.name || data.birthDate;

  const onFieldChange = useCallback(
    (name, value) => {
      const newData = { ...data };
      newData[name] = value;
      onChange(newData);
    },
    [data, onChange]
  );

  return (
    <>
      <TextField
        required={areFieldsRequired}
        label={t("Child's Name")}
        value={data.name}
        onChange={(e) => onFieldChange("name", e.target.value)}
      />
      <DatePicker
        required={areFieldsRequired}
        label={t("Child's Date of Birth") + (areFieldsRequired ? " *" : "")}
        value={maybeDate(data.birthDate)}
        onChange={(value) => onFieldChange("birthDate", value)}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.isMember}
              onChange={(e) => onFieldChange("isMember", e.target.checked)}
            />
          }
          label={t("Child is also a member?")}
        />
      </FormGroup>
    </>
  );
}

function CreationStatusField({ value, onChange, required }) {
  const labelId = useId();
  const { t } = useTranslation();

  return (
    <FormControl required={required}>
      <FormLabel id={labelId}>{t("Membership Method")}</FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        value={value}
        onChange={changeHandler(onChange)}
      >
        <FormControlLabel
          value="RECEIVED_BY_BAPTISM"
          control={<Radio />}
          label={t("Batismo")}
        />
        <FormControlLabel
          value="RECEIVED_WITHOUT_LETTER"
          control={<Radio />}
          label={t("Recebimento - Sem Carta")}
        />
        <FormControlLabel
          value="TRANSFERRED_WITH_LETTER_SAME_MINISTRY"
          control={<Radio />}
          label={t("Transferência -  Com Carta de Mudança - Mesmo Ministério")}
        />
        <FormControlLabel
          value="TRANSFERRED_WITH_LETTER"
          control={<Radio />}
          label={t(
            "Transferência -  Com Carta de Mudança - Ministério Diferente"
          )}
        />
      </RadioGroup>
    </FormControl>
  );
}

function DocumentRows({ documents, onChange }) {
  const { t } = useTranslation();
  const lastIndex = documents.length - 1;

  const onAddDocClick = useCallback(() => {
    const newValue = [
      ...documents,
      {
        clientId: `document_${++globalFileIndex}`,
        file: null,
      },
    ];
    onChange(newValue);
  }, [onChange, documents]);

  const onRemoveDocClick = useCallback(
    (docIndex) => {
      const newValue = [...documents];
      newValue[docIndex].removed = true;
      onChange(newValue);
    },
    [onChange, documents]
  );

  const onDocChange = useCallback(
    (e, docIndex) => {
      const file = e.target.files[0];

      if (file && !DOCUMENT_ALLOWED_FORMATS.includes(file.type)) {
        alert(t("Document file format is invalid."));
        return;
      }

      const newValue = [...documents];
      newValue[docIndex].file = file;
      onChange(newValue);
    },
    [onChange, documents, t]
  );

  const onDocTypeChange = (e, docIndex) => {
    const type = e.target.value;
    const newValue = [...documents];
    newValue[docIndex].type = type;
    onChange(newValue);
  };

  return (
    <>
      {documents.map((doc, i) => (
        <Stack
          direction="row"
          spacing={1}
          key={doc.id ?? doc.clientId}
          sx={{ flex: 1, display: doc.removed ? "none" : "flex" }}
          alignItems="center"
        >
          <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              accept="image/*"
              label={t("Document")}
              type={doc.id ? "text" : "file"}
              onChange={doc.id ? null : (e) => onDocChange(e, i)}
              readonly={doc.id != null}
              value={doc.id != null ? doc?.file?.name : undefined}
            />

            <FormControl sx={{ flex: 1 }}>
              <InputLabel>{t("Type")}</InputLabel>
              <Select value={doc.type} onChange={(e) => onDocTypeChange(e, i)}>
                <MenuItem value={DocumentType.OFFICIAL_DOCUMENT}>
                  {t("Document Pessoal")}
                </MenuItem>
                <MenuItem value={DocumentType.MARRIAGE_DOCUMENT}>
                  {t("Certidão de Casamento")}
                </MenuItem>
                <MenuItem value={DocumentType.THEOLOGY_CERTIFICATE}>
                  {t("Certificado de Curso (Teologia)")}
                </MenuItem>
                <MenuItem value={DocumentType.COURSE_CERTIFICATE}>
                  {t("Certificado de Curso (outros)")}
                </MenuItem>
                <MenuItem value={DocumentType.TRANSFERRED_LETTER}>
                  {t("Carta de Transferência")}
                </MenuItem>
                <MenuItem value={DocumentType.OTHER}>{t("Outro")}</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {i === lastIndex ? (
            <IconButton
              sx={{ flex: 0, width: 40, height: 40 }}
              size="medium"
              onClick={onAddDocClick}
              aria-label={t("Add Document")}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <IconButton
              sx={{ flex: 0, width: 40, height: 40 }}
              size="medium"
              onClick={() => onRemoveDocClick(i)}
              aria-label={t("Remove Document")}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Stack>
      ))}
    </>
  );
}

function isNullOrEmpty(value) {
  return value == null || value === "";
}

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}

function maybeDate(value) {
  return value != null ? moment(value) : null;
}
