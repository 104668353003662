import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import fetch from "data/fetch";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import { MuiTelInput } from "mui-tel-input";
import moment from "moment";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { CountrySelector } from "shared/components/CountrySelector/CountrySelector.react";

export default function ProfileEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    name: "",
    phone: "",
    email: "",
    gender: "MASCULINO",
    preferredContactMethod: "EMAIL",
    birthDate: null,
    address: { street: "", postCode: "", city: "", state: "", country: "" },
    maritalStatus: "SINGLE",
    marriageAt: null,
    waterBaptismAt: null,
    spiritBaptismAt: null,
    spouseName: "",
    spouseBirthDate: null,
    spouseIsMember: false,
    childrenInfo: [{ name: "", birthDate: null, isMember: false }],
    photo: null,
  });

  const [congregation, setCongregation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    fetch("/v1/profile")
      .then((data) => {
        console.log("Response: ", data);

        const children =
          data.familyMembers?.filter((member) => member.type === "CHILD") || [];
        const spouse = data.familyMembers?.find(
          (member) => member.type === "SPOUSE"
        );

        setProfileData({
          ...data,
          birthDate: data.birthDate ? moment(data.birthDate) : null,
          marriageAt: data.marriageAt ? moment(data.marriageAt) : null,
          waterBaptismAt: data.waterBaptismAt
            ? moment(data.waterBaptismAt)
            : null,
          spiritBaptismAt: data.spiritBaptismAt
            ? moment(data.spiritBaptismAt)
            : null,
          spouseName: spouse?.name || "",
          spouseBirthDate: spouse?.birthDate ? moment(spouse.birthDate) : null,
          spouseIsMember: spouse?.isMember || false,
          childrenInfo: children.map((child) => ({
            name: child.name,
            birthDate: child.birthDate ? moment(child.birthDate) : null,
            isMember: child.isMember || false,
          })),
        });

        setCongregation(data.congregacao || null);
        setIsDataLoaded(true);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
      });
  }, []);

  const handleInputChange = (field, value) => {
    setProfileData({ ...profileData, [field]: value });
    setFormErrors({ ...formErrors, [field]: null });
  };

  const handleAddressChange = (field, value) => {
    setProfileData({
      ...profileData,
      address: { ...profileData.address, [field]: value },
    });
    setFormErrors({
      ...formErrors,
      address: { ...formErrors.address, [field]: null },
    });
  };

  const handleChildChange = (child, index) => {
    const updatedChildren = [...profileData.childrenInfo];
    updatedChildren[index] = child;
    setProfileData({ ...profileData, childrenInfo: updatedChildren });
  };

  const addChild = () => {
    setProfileData({
      ...profileData,
      childrenInfo: [
        ...profileData.childrenInfo,
        { name: "", birthDate: null, isMember: false },
      ],
      address: {
        ...profileData.address,
        country: profileData.address.country?.value || null,
      },
    });
  };

  const removeChild = (index) => {
    const updatedChildren = [...profileData.childrenInfo];
    updatedChildren.splice(index, 1);
    setProfileData({ ...profileData, childrenInfo: updatedChildren });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setProfileData({ ...profileData, photo: file });
  };

  const validateForm = () => {
    const errors = {};
    const { address } = profileData;

    if (!profileData.name) errors.name = t("Name is required.");
    if (!profileData.phone) errors.phone = t("Phone is required.");
    if (!profileData.gender) errors.gender = t("Gender is required.");
    if (!profileData.birthDate)
      errors.birthDate = t("Date of Birth is required.");
    if (!address.street)
      errors.address = { ...errors.address, street: t("Street is required.") };
    if (!address.postCode)
      errors.address = {
        ...errors.address,
        postCode: t("Post Code is required."),
      };
    if (!address.city)
      errors.address = { ...errors.address, city: t("City is required.") };
    if (!address.state)
      errors.address = { ...errors.address, state: t("State is required.") };
    if (!address.country)
      errors.address = {
        ...errors.address,
        country: t("Country is required."),
      };

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setErrorMessage("");
    setIsSubmitting(true);

    let photoFileId = null;

    if (profileData.photo && !profileData.photo.id) {
      try {
        const photoData = new FormData();
        photoData.append("file", profileData.photo);
        photoData.append("name", profileData.photo.name);
        photoData.append("mime", profileData.photo.type);

        const photoResult = await fetch("/v1/file", {
          method: "POST",
          body: photoData,
        });

        photoFileId = photoResult.id;
      } catch (e) {
        setErrorMessage(
          e.apiMessage || "Sorry, there was an error uploading your photo."
        );
        setIsSubmitting(false);
        return;
      }
    }

    const familyMembers = [];
    if (profileData.spouseName) {
      familyMembers.push({
        name: profileData.spouseName,
        birthDate: profileData.spouseBirthDate
          ? moment(profileData.spouseBirthDate).format("YYYY-MM-DD")
          : null,
        isMember: profileData.spouseIsMember,
        type: "SPOUSE",
      });
    }

    profileData.childrenInfo
      .filter((c) => c.name && c.birthDate)
      .forEach((child) => {
        familyMembers.push({
          name: child.name,
          birthDate: moment(child.birthDate).format("YYYY-MM-DD"),
          isMember: child.isMember,
          type: "CHILD",
        });
      });

    const payload = {
      name: profileData.name,
      phone: profileData.phone,
      email: profileData.email,
      gender: profileData.gender,
      prefContact: profileData.preferredContactMethod,
      birthDate: profileData.birthDate
        ? moment(profileData.birthDate).format("YYYY-MM-DD")
        : null,
      address: profileData.address,
      maritalStatus: profileData.maritalStatus,
      marriageAt: profileData.marriageAt
        ? moment(profileData.marriageAt).format("YYYY-MM-DD")
        : null,
      waterBaptismAt: profileData.waterBaptismAt
        ? moment(profileData.waterBaptismAt).format("YYYY-MM-DD")
        : null,
      spiritBaptismAt: profileData.spiritBaptismAt
        ? moment(profileData.spiritBaptismAt).format("YYYY-MM-DD")
        : null,
      familyMembers,
      congregacaoId: profileData.congregacao?.id ?? null,
      documents: photoFileId
        ? [{ fileId: photoFileId, type: "OFFICIAL_PHOTO" }]
        : [],
    };

    try {
      await fetch("/v1/profile", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      setShowSuccessDialog(true);
    } catch (err) {
      setErrorMessage(t("Failed to update profile. Please try again."));
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container maxWidth="sm" component="form" onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {/* First Section */}
          <FormCard title={t("Contact Information")}>
            <CongregationSelect
              initialValue={congregation}
              required
              onChange={(selectedCongregation) =>
                setCongregation(selectedCongregation)
              }
            />
            <TextField
              label={t("Name")}
              value={profileData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required
            />
            <FormControl>
              <FormLabel>{t("Gender")}</FormLabel>
              <RadioGroup
                required
                row
                value={profileData.gender}
                onChange={(e) => handleInputChange("gender", e.target.value)}
              >
                <FormControlLabel
                  value="MASCULINO"
                  control={<Radio />}
                  label={t("Male")}
                />
                <FormControlLabel
                  value="FEMININO"
                  control={<Radio />}
                  label={t("Female")}
                />
              </RadioGroup>
            </FormControl>
            <TextField label={t("Email")} value={profileData.email} disabled />
            <MuiTelInput
              label={t("Phone")}
              value={profileData.phone}
              onChange={(value) => handleInputChange("phone", value)}
              required
            />
            <FormControl>
              <FormLabel>{t("Preferred Contact Method")}</FormLabel>
              <RadioGroup
                row
                value={profileData.prefContact}
                onChange={(e) =>
                  handleInputChange("preferredContactMethod", e.target.value)
                }
                required
              >
                <FormControlLabel
                  value="EMAIL"
                  control={<Radio />}
                  label={t("Email")}
                />
                <FormControlLabel
                  value="PHONE"
                  control={<Radio />}
                  label={t("Phone")}
                />
              </RadioGroup>
            </FormControl>
          </FormCard>

          {/* Second Section */}
          <FormCard title={t("Personal Information")}>
            <DatePicker
              required
              label={t("Date of Birth")}
              value={profileData.birthDate}
              onChange={(value) => handleInputChange("birthDate", value)}
            />
            <TextField
              required
              label={t("Address")}
              value={profileData.address?.street || ""}
              onChange={(e) => handleAddressChange("street", e.target.value)}
              error={!!formErrors.address?.street}
              helperText={formErrors.address?.street}
            />
            <TextField
              required
              label={t("City")}
              value={profileData.address?.city || ""}
              onChange={(e) => handleAddressChange("city", e.target.value)}
              error={!!formErrors.address?.city}
              helperText={formErrors.address?.city}
            />
            <TextField
              required
              label={t("State")}
              value={profileData.address?.state || ""}
              onChange={(e) => handleAddressChange("state", e.target.value)}
              error={!!formErrors.address?.state}
              helperText={formErrors.address?.state}
            />
            <TextField
              required
              label={t("Postal Code")}
              value={profileData.address?.postCode || ""}
              onChange={(e) => handleAddressChange("postCode", e.target.value)}
              error={!!formErrors.address?.postCode}
              helperText={formErrors.address?.postCode}
            />
            <FormControl error={!!formErrors.address?.country} fullWidth>
              <CountrySelector
                required
                value={profileData.address?.country || ""}
                onChange={(newCountry) =>
                  handleAddressChange("country", newCountry)
                }
              />
              {!!formErrors.address?.country && (
                <FormHelperText>{formErrors.address?.country}</FormHelperText>
              )}
            </FormControl>
            <TextField
              InputLabelProps={{ shrink: true }}
              accept="image/*"
              type="file"
              label={t("Personal Photo")}
              onChange={handlePhotoChange}
            />
          </FormCard>

          {/* Third Section */}
          <FormCard title={t("Family Information")}>
            <FormControl>
              <InputLabel>{t("Marital Status")}</InputLabel>
              <Select
                value={profileData.maritalStatus}
                onChange={(e) =>
                  handleInputChange("maritalStatus", e.target.value)
                }
              >
                <MenuItem value="SINGLE">{t("Single")}</MenuItem>
                <MenuItem value="MARRIED">{t("Married")}</MenuItem>
                <MenuItem value="DIVORCED">{t("Divorced")}</MenuItem>
                <MenuItem value="WIDOWED">{t("Widowed")}</MenuItem>
              </Select>
            </FormControl>
            {profileData.maritalStatus === "MARRIED" && (
              <>
                <TextField
                  label={t("Spouse's Name")}
                  value={profileData.spouseName}
                  onChange={(e) =>
                    handleInputChange("spouseName", e.target.value)
                  }
                />
                <DatePicker
                  label={t("Spouse's Date of Birth")}
                  value={profileData.spouseBirthDate}
                  onChange={(value) =>
                    handleInputChange("spouseBirthDate", value)
                  }
                />
                <DatePicker
                  label={t("Marriage Date")}
                  value={profileData.marriageAt}
                  onChange={(value) => handleInputChange("marriageAt", value)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profileData.spouseIsMember}
                      onChange={(e) =>
                        handleInputChange("spouseIsMember", e.target.checked)
                      }
                    />
                  }
                  label={t("Spouse is also a member?")}
                />
              </>
            )}
            {profileData.childrenInfo.map((child, index) => (
              <Fragment key={index}>
                <TextField
                  label={t("Child's Name")}
                  value={child.name}
                  onChange={(e) =>
                    handleChildChange({ ...child, name: e.target.value }, index)
                  }
                />
                <DatePicker
                  label={t("Child's Date of Birth")}
                  value={child.birthDate ? moment(child.birthDate) : null}
                  onChange={(value) =>
                    handleChildChange({ ...child, birthDate: value }, index)
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={child.isMember}
                      onChange={(e) =>
                        handleChildChange(
                          { ...child, isMember: e.target.checked },
                          index
                        )
                      }
                    />
                  }
                  label={t("Child is also a member?")}
                />
                <Button
                  startIcon={<RemoveIcon />}
                  variant="contained"
                  size="medium"
                  onClick={() => removeChild(index)}
                >
                  {t("Remove Child")}
                </Button>
              </Fragment>
            ))}
            <Button
              onClick={addChild}
              startIcon={<AddIcon />}
              variant="outlined"
            >
              {t("Add Child")}
            </Button>
          </FormCard>

          {/* Fourth Section */}
          <FormCard title={t("Congregational Information")}>
            <DatePicker
              label={t("Baptism Date")}
              value={profileData.waterBaptismAt}
              onChange={(value) => handleInputChange("waterBaptismAt", value)}
            />
            <DatePicker
              label={t("Holy Spirit Baptism Date")}
              value={profileData.spiritBaptismAt}
              onChange={(value) => handleInputChange("spiritBaptismAt", value)}
            />
          </FormCard>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Stack direction="row" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? t("Submitting...") : t("Save")}
            </Button>
          </Stack>
        </Stack>
      </Container>

      {/* Success Dialog */}
      <Dialog
        open={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
          navigate("/profile"); // Redirect after success
        }}
      >
        <DialogTitle>
          <CheckCircleIcon
            color="success"
            fontSize="large"
            style={{ verticalAlign: "middle", marginRight: "8px" }}
          />
          {t("Profile Updated")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Your profile has been updated successfully.")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSuccessDialog(false);
              navigate("/profile");
            }}
            autoFocus
          >
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function FormCard({ title, children }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Stack spacing={2}>{children}</Stack>
      </CardContent>
    </Card>
  );
}
